module.exports = [{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en","langKeyForNull":"en","prefixDefault":false,"useLangKeyLayout":false,"configPath":"/opt/build/repo/i18n/config.json"},
    },{
      plugin: require('../plugins/gatsby-theme-i18n-i18next-wrapper/gatsby-browser.js'),
      options: {"plugins":[],"locales":"./i18n/l10n"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-theme-starberry-sirius","short_name":"sirius","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"/opt/build/repo/src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"70b73439d6cfc129749c25a28e80ccca"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
